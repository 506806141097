import {
  GET_CASE_MANAGERS,
  CASE_MANAGERS_ERROR,
  CASE_MANAGERS_LOADING,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  data: { users: [] },
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
};

const caseManagersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(CASE_MANAGERS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(CASE_MANAGERS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_CASE_MANAGERS, (state, { payload }) => {
      state.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...state?.data, ...payload?.data];
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.hasMore = payload.hasMore;
      state.count = payload.count;
      state.loading = false;
    });
});

export default caseManagersReducer;
