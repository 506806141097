import { getUser } from "../auth";
import { APP_CONFIG } from "../types";

const appConfigSync = async (dispatch) => {
  await getUser(dispatch);
};

export const appConfigAction = () => (dispatch) => {
  return appConfigSync(dispatch)
    .then(() => {
      dispatch({ type: APP_CONFIG, payload: {} });
    })
    .catch((e) => {
      console.error(e);
    });
};
