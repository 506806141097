export const APP_CONFIG = "APP_CONFIG";

export const USER_LOADED = "USER_LOADED";
export const USER_LOADING = "USER_LOADING";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const PASSWORD_REQUEST_ERROR = "PASSWORD_RESET_ERROR";
export const PASSWORD_REQUEST_SUCCESS = "PASSWORD_REQUEST_SUCCESS";
export const PASSWORD_VERIFY_ERROR = "PASSWORD_VERIFY_ERROR";
export const PASSWORD_VERIFY_SUCCESS = "PASSWORD_VERIFY_SUCCESS";
export const PASSWORD_RESET_ERROR = "PASSWORD_RESET_ERROR";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";

export const BOT_APP_CONFIG = "BOT_APP_CONFIG";
export const LOAD_CHAT = "LOAD_CHAT";

export const ADD_MESSAGE = "ADD_MESSAGE";

export const GET_PACKAGES = "GET_PACKAGES";
export const PACKAGES_LOADING = "PACKAGES_LOADING";
export const PACKAGES_ERROR = "PACKAGES_ERROR";
export const GET_PACKAGE = "GET_PACKAGE";
export const PACKAGE_LOADING = "PACKAGE_LOADING";
export const PACKAGE_ERROR = "PACKAGE_ERROR";

export const SPECIALIZATION_LOADING = "SPECIALIZATION_LOADING";
export const SPECIALIZATION_ERROR = "SPECIALIZATION_ERROR";
export const GET_SPECIALIZATION = "GET_SPECIALIZATION";

///Global
export const SOCKETCONNECTED = "SOCKETCONNECTED";
export const START_SHAKING = "START_SHAKING";

/// nOTIFICATIONS
export const LIST_NOTIFICATIONS = "LIST_NOTIFICATIONS";
export const LIST_NOTIFICATIONS_LOADING = "LIST_NOTIFICATIONS_LOADING";
export const LIST_NOTIFICATIONS_ERROR = "LIST_NOTIFICATIONS_ERROR";
export const INSERT_NEW_NOTIFICATION = "INSERT_NEW_NOTIFICATION";
export const UPDATE_UN_READ = "UPDATE_UN_READ";

// Hospitals
export const GET_HOSPITALS = "GET_HOSPITALS";
export const HOSPITALS_ERROR = "HOSPITALS_ERROR";
export const HOSPITALS_LOADING = "HOSPITALS_LOADING";
export const GET_HOSPITAL = "GET_HOSPITAL";
export const HOSPITAL_ERROR = "HOSPITAL_ERROR";
export const HOSPITAL_LOADING = "HOSPITAL_LOADING";

// Doctors
export const GET_DOCTORS = "GET_DOCTORS";
export const DOCTORS_ERROR = "DOCTORS_ERROR";
export const DOCTORS_LOADING = "DOCTORS_LOADING";
export const GET_DOCTOR = "GET_DOCTOR";
export const DOCTOR_ERROR = "DOCTOR_ERROR";
export const DOCTOR_LOADING = "DOCTOR_LOADING";

// Entertainment
export const GET_ENTERTAINMENTS = "GET_ENTERTAINMENTS";
export const ENTERTAINMENTS_ERROR = "ENTERTAINMENTS_ERROR";
export const ENTERTAINMENTS_LOADING = "ENTERTAINMENTS_LOADING";

// Transportation
export const GET_TRANSPORTATION = "GET_TRANSPORTATION";
export const TRANSPORTATION_ERROR = "TRANSPORTATION_ERROR";
export const TRANSPORTATION_LOADING = "TRANSPORTATION_LOADING";

// tour
export const GET_TOUR = "GET_TOUR";
export const TOUR_ERROR = "TOUR_ERROR";
export const TOUR_LOADING = "TOUR_LOADING";

export const ENTERTAINMENTS_LOADING_HISTORY = "ENTERTAINMENTS_LOADING_HISTORY";
export const ENTERTAINMENTS_ERROR_HISTORY = "ENTERTAINMENTS_ERROR_HISTORY";
export const GET_ENTERTAINMENTS_HISTORY = "GET_ENTERTAINMENTS_HISTORY";

//Chats
export const CHATS_LOADED = "CHATS_LOADED";
export const ADD_SINGLE_CHAT = "ADD_SINGLE_CHAT";
export const CHATS_LOADING = "CHATS_LOADING";
export const CHATS_ERROR = "CHATS_ERROR";
export const UPDATE_CHATS_WHEN_MESSAGE_GOT = "UPDATE_CHATS_WHEN_MESSAGE_GOT";
export const UPDATE_CHAT_UNSEEN_MESSAGE_COUNT =
  "UPDATE_CHAT_UNSEEN_MESSAGE_COUNT";

//SingleChat
export const SINGLE_CHAT_LOADED = "SINGLE_CHAT_LOADED";
export const SINGLE_CHAT_LOADING = "SINGLE_CHAT_LOADING";
export const SINGLE_CHAT_ERROR = "SINGLE_CHAT_ERROR";
export const SET_TEMP_CHAT = "SET_TEMP_CHAT";
export const INSERT_NEW_MESSAGE = "INSERT_NEW_MESSAGE";
export const MESSAGE_SENT = "MESSAGE_SENT";
export const EMPTY_SINGLE_CHAT = "EMPTY_SINGLE_CHAT";

///Users
export const SET_CURRENT_USERS = "SET_CURRENT_USERS";
export const GET_CASE_MANAGERS = "GET_CASE_MANAGERS";
export const CASE_MANAGERS_ERROR = "CASE_MANAGERS_ERROR";
export const CASE_MANAGERS_LOADING = "CASE_MANAGERS_LOADING";
