import { Suspense, lazy, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "../layout/Navbar";
import PageLoader from "../components/PageLoader";
import Footer from "../layout/Footer";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { appConfigAction } from "../store/actions/app/index";

const Booking = lazy(() => import("../pages/Booking"));
const TrackBooking = lazy(() => import("../pages/Booking/TrackBooking"));
const Home = lazy(() => import("../pages/Home"));
const Chat = lazy(() => import("../pages/ChatBot"));
const Doctors = lazy(() => import("../pages/Doctors"));
const DoctorPage = lazy(() => import("../pages/Doctors/DoctorPage"));
const HospitalsPage = lazy(() => import("../pages/HospitalsPage"));
const Specializations = lazy(() => import("../pages/Specializations"));
const WellnessCenterPage = lazy(() => import("../pages/WellnessCenter"));
const HospitalDetails = lazy(() => import("../pages/SingleHospital"));
const TreatmentForm = lazy(() => import("../pages/TreatmentForm"));
const Login = lazy(() => import("../pages/Login"));
const ForgotPassword = lazy(() => import("../pages/Login/ForgotPassword"));
const Verification = lazy(() => import("../pages/Login/Verification"));
const ResetPassword = lazy(() => import("../pages/Login/ResetPassword"));
const PackageDetails = lazy(() => import("../pages/Package"));
const Chats = lazy(() => import("../pages/Chats/Chats"));

export const Router = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const scrollToTop = (url) => {
    if (!url.includes("#")) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToTop(window.location.href);
  }, [location]);

  const isHomePage =
    location.pathname === "/" || location.pathname === "/wellness-center";

  const appLoading = useSelector((state) => state.app.loading);

  useEffect(() => {
    dispatch(appConfigAction());
  }, [dispatch]);

  if (appLoading) return <PageLoader />;

  return (
    <>
      <Navbar />
      <Box sx={{ marginTop: isHomePage ? "0" : "80px" }}>
        <Routes>
          <Route
            path="/login"
            element={
              <Suspense fallback={<PageLoader />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <Suspense fallback={<PageLoader />}>
                <ForgotPassword />
              </Suspense>
            }
          />
          <Route
            path="/verification"
            element={
              <Suspense fallback={<PageLoader />}>
                <Verification />
              </Suspense>
            }
          />
          <Route
            path="/reset-password"
            element={
              <Suspense fallback={<PageLoader />}>
                <ResetPassword />
              </Suspense>
            }
          />
          <Route
            path="/"
            element={
              <Suspense fallback={<PageLoader />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path="/package-details/:id"
            element={
              <Suspense fallback={<PageLoader />}>
                <PackageDetails />
              </Suspense>
            }
          />
          <Route
            path="/booking"
            element={
              <Suspense fallback={<PageLoader />}>
                <Booking />
              </Suspense>
            }
          />
          <Route
            path="/chatbot"
            element={
              <Suspense fallback={<PageLoader />}>
                <Chat />
              </Suspense>
            }
          />
          <Route
            path="/track"
            element={
              <Suspense fallback={<PageLoader />}>
                <TrackBooking />
              </Suspense>
            }
          />
          <Route
            path="/track/:id"
            element={
              <Suspense fallback={<PageLoader />}>
                <TrackBooking />
              </Suspense>
            }
          />
          <Route
            path="/doctors"
            element={
              <Suspense fallback={<PageLoader />}>
                <Doctors />
              </Suspense>
            }
          />
          <Route
            path="/doctor/:id"
            element={
              <Suspense fallback={<PageLoader />}>
                <DoctorPage />
              </Suspense>
            }
          />
          <Route
            path="/hospitals"
            element={
              <Suspense fallback={<PageLoader />}>
                <HospitalsPage />
              </Suspense>
            }
          />

          <Route
            path="/hospital/:id"
            element={
              <Suspense fallback={<PageLoader />}>
                <HospitalDetails />
              </Suspense>
            }
          />

          <Route
            path="/specializations"
            element={
              <Suspense fallback={<PageLoader />}>
                <Specializations />
              </Suspense>
            }
          />

          <Route
            path="/wellness-center"
            element={
              <Suspense fallback={<PageLoader />}>
                <WellnessCenterPage />
              </Suspense>
            }
          />

          <Route
            path="/treatment-form"
            element={
              <Suspense fallback={<PageLoader />}>
                <TreatmentForm />
              </Suspense>
            }
          />

          <Route
            path="/chat"
            element={
              <Suspense fallback={<PageLoader />}>
                <Chats />
              </Suspense>
            }
          />

          <Route path="404" element={<>Not Found</>} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </Box>

      <Footer />
    </>
  );
};

export default Router;
