import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getNotificationsAction,
  totalUnReadAction,
} from "../../store/actions/notifcations";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { ListItemButton, Typography } from "@mui/material";
import NotificationItem from "./NotificationItem";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

export default function NotificationsModal({ anchorEl, handleClose }) {
  const {
    t,
    i18n: { language: appLang },
  } = useTranslation(["notifications"]);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { data, thisPage, loading } = useSelector((s) => s?.notifications);

  useEffect(() => {
    totalUnReadAction();
    if (thisPage < 1 && anchorEl && dispatch) {
      getNotificationsAction({ page: 1, limit: 5 })(dispatch);
    }
  }, [anchorEl, dispatch, thisPage]);

  return (
    <Menu
      id={"Notification_modal"}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      px
      sx={{
        height: "500px",
        zIndex: 999999999999,
        p: 0,
        m: 0,
      }}
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
    >
      <Box>
        <Typography sx={{ px: 2, py: 1 }}>Notifications</Typography>
      </Box>

      {data?.length === 0 && !loading && (
        <MenuItem>
          <Box sx={{ p: 2 }}>
            <Typography color="text.secondary" variant="body2">
              {t("empty")}
            </Typography>
          </Box>{" "}
        </MenuItem>
      )}
      {data?.map((notification) => {
        return (
          <NotificationItem
            notification={notification}
            key={notification.id}
            isModal
            appLang={appLang}
          />
        );
      })}
      {data?.length > 5 && (
        <MenuItem>
          <Typography color="text.secondary" variant="body2">
            {t("click_to_view")}
          </Typography>
        </MenuItem>
      )}

      {/* <Box sx={{ height: "60px" }}></Box>
      <ListItemButton
        sx={{
          height: "50px",
          backgroundColor: "primary.main",
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          textAlign: "center",
          color: "primary.white",
          "&:hover": { color: "text.primary" },
        }}
      >
        <Typography
          sx={{ textAlign: "center", width: 1 }}
          variant="body2"
          fontWeight="bold"
          onClick={() => nav(`/notification`)}
        >
          {t("view_notifications")}
        </Typography>
      </ListItemButton> */}
    </Menu>
  );
}
